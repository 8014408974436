<!--
 * @Description: 自我确认
 * @Author: transparent
 * @Date: 2022-02-21 13:48:22
 * @LastEditTime: 2022-08-16 16:48:49
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/costManagementSelfConfirmation.vue
-->
<template>
  <div>
    <el-main>
      <el-form
        :model="params"
        ref="costManagementForm"
        label-width="120px"
        class="fromStyle"
      >
        <el-form-item label="日程" prop="refId">
          <el-select
            v-model="params.refId"
            @visible-change="getCalendarScheduleSelectList($event)"
            filterable
            placeholder="请选择日程"
            clearable
            :no-data-text="
              !calendarScheduleSelectList ? '加载中···' : '暂无数据'
            "
          >
            <el-option
              v-for="item in calendarScheduleSelectList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
              {{ item.title }}
              <span class="workflowVersion">{{
                item.ownerId == userId ? "我的" : item.ownerName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行程" prop="title">
          <el-select
            v-model="params.title"
            @visible-change="getScheduleTriplist($event)"
            filterable
            placeholder="请选择"
            clearable
            :no-data-text="!scheduleTriplist ? '加载中···' : '暂无数据'"
          >
            <el-option
              v-for="(item, index) in scheduleTriplist"
              :key="index"
              :value="item.fromCity + '->' + item.toCity"
            >
              <span>{{ item.fromCity }}->{{ item.toCity }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报销申请人" prop="userId">
          <el-select
            v-model="params.userId"
            @visible-change="findMyProjectWorkflowMembers($event)"
            filterable
            placeholder="请选择费用关联人"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in myProjectWorkflowMembers"
              :key="item.uid"
              :label="item.real_name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="费用发生时间" prop="searchDate">
          <el-date-picker
            v-model="params.searchDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="timestamp"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>

        <div style="margin-left: 80px; float: right">
          <el-button @click="resetConditions">重置</el-button>
          <el-button type="primary" @click="openDialog('search')"
            >查询</el-button
          >
          <el-button
            type="primary"
            @click="openDialog('add')"
            v-permission="['admin:costManagementSelfConfirmation:add']"
            >费用录入</el-button
          >
        </div>
      </el-form>

      <!-- 表格 -->
      <el-table
        border
        ref="multipleTable"
        :data="tableData"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="日程" prop="refName"></el-table-column>

        <el-table-column label="出行人员" prop="userName">
          <template slot-scope="scope">
            <span>
              {{ scope.row.userName }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="最新进展状态" prop="preApplyStatus">
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.preApplyStatus == 2"
              placement="top"
              title="钉钉审核状态"
              width="200"
              trigger="click"
            >
              <div>{{ scope.row.formalApplyStatus | formalApplyStatus }}</div>
              <span class="preApplyStatus" slot="reference">
                {{ scope.row.preApplyStatus | preApplyStatus }}
              </span>
            </el-popover>
            <span v-else>
              {{ scope.row.preApplyStatus | preApplyStatus }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="最新进展说明" prop="latestRemark" width="220">
          <template slot-scope="scope">
            <p >{{scope.row.latestRemark}}</p>
          </template>
        </el-table-column>
        <el-table-column label="最新进展时间" prop="latestRemarkTime">
          <template slot-scope="scope">
            <span>{{ scope.row.latestRemarkTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否确认" prop="isConfirm">
          <template slot-scope="scope">
            <span>{{ scope.row.isConfirm | isConfirm }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="390">
          <template v-slot="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('confirm', row)"
              v-permission="['admin:costManagementSelfConfirmation:confirm']"
              :disabled="disabledConfirm(row)"
              >申请人填报 & 确认</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('urge', row)"
              v-permission="['admin:costManagementSelfConfirmation:urge']"
              :disabled="selectAble(row)"
              >催TA</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="openDialog('launch', row)"
              v-permission="['admin:costManagementSelfConfirmation:launch']"
              :disabled="disabledLaunch(row)"
              :loading="row.launchLoading"
              >发起报销申请</el-button
            >
            <el-popover
              trigger="hover"
              placement="top"
              width="40"
              :ref="row.id"
            >
              <el-button
                type="primary"
                size="mini"
                @click="openDialog('check', row)"
              >
                查看
              </el-button>
              <el-button
                icon="el-icon-view"
                slot="reference"
                style="margin: 0 0 0 10px; font-size: 18px"
                size="small"
                type="text"
              ></el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </el-main>
    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :id="costId"
      :costInfo="costInfo"
    ></edit>
    <editExpenseDetail
      v-if="expenseDetailDialog"
      :expenseDetailDialog="expenseDetailDialog"
      v-on:listenExpenseDetailClose="listenExpenseDetailClose"
      :type="expenseDetailType"
    ></editExpenseDetail>
  </div>
</template>

<script>
import {
  getExpenseList,
  expenseNoticeUser,
  expensePreApprove,
  expenseBatchPreApprove,
  expenseStartFormalApprove,
  getScheduleTriplist,
} from "@/api/costManagement";
import edit from "../components/costManagement/edit";
import editExpenseDetail from "@/components/costManagement/editExpenseDetail";
import { formatDate } from "@/common/date";
import {
  getCalendarScheduleSelectList,
  getCalendarThingDetails,
} from "@/api/calendar";
import { findMyProjectWorkflowMembers } from "@/api/myMission";
import eventBus from "@/common/eventBus";
export default {
  name: "costManagementSelfConfirmation",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      addDialog: false,
      typeDialog: "",
      roleCode: "",
      costId: null,
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      multipleSelection: [],
      userId: window.localStorage.getItem("userId"),
      costInfo: null,
      calendarScheduleSelectList: [],
      scheduleTriplist: [],
      myProjectWorkflowMembers: [],
      feeTypeList: [],
      params: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      expenseDetailType: null,
      expenseDetailDialog: false,
      scheduleDetails: null,
      launchLoading: false,
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    preApplyStatus(value) {
      switch (value) {
        case 0:
          return "填报中";
        case 1:
          return "待财务预审";
        case 2:
          return "财务预审通过";
        case 3:
          return "财务审核拒绝";
      }
    },
    formalApplyStatus(value) {
      switch (value) {
        case 0:
          return "暂无状态";
        case 1:
          return "申请中";
        case 2:
          return "审核通过";
        case 3:
          return "审核拒绝";
      }
    },
    isConfirm(value) {
      switch (value) {
        case 0:
          return "未确认";
        case 1:
          return "已确认";
      }
    },
  },
  components: {
    edit,
    editExpenseDetail,
  },
  activated() {
    this.queryExpenseList(1, this.pageInfo.pageSize);
    eventBus.$on("expenseUserNotice", (info) => {
      if (info) {
        console.log(info);
        this.openDialog("confirm", info.detailInfo);
      }
    });
  },
  mounted() {
    // this.queryExpenseList(1, this.pageInfo.pageSize);
  },
  beforeDestroy() {
    eventBus.$off("expenseUserNotice");
  },
  methods: {
    disabledConfirm(row, index) {
      if (
        (row.preApplyStatus == 0 || row.preApplyStatus == 3) &&
        row.userId == this.userId
      ) {
        return false;
      } else {
        return true;
      }
    },
    selectAble(row, index) {
      if (
        row.relatedExpenseInfo.list.length >
          row.relatedExpenseInfo.confirmedCount &&
        row.userId != this.userId &&
        row.preApplyStatus == 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    disabledLaunch(row, index) {
      if (
        row.preApplyStatus == 2 &&
        row.settleAmount !== 0 &&
        row.userId == this.userId &&
        (row.formalApplyStatus == 0 || row.formalApplyStatus == 3)
      ) {
        return false;
      } else {
        return true;
      }
    },
    listenExpenseDetailClose(params) {
      let that = this;
      console.log(params);
      const { type, isClose, info } = params;
      that.expenseDetailDialog = !isClose;
      if (type == "sure") {
        this.queryExpenseList(1, this.pageInfo.pageSize);
      }
    },
    resetConditions() {
      this.$refs.costManagementForm.resetFields();
      this.params.endDate = null;
      this.params.startDate = null;
      this.params.searchDate = null;
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.queryExpenseList(this.pageInfo.pageNum, this.pageInfo.pageSize);
    },
    async selectListDict(cb, type) {
      if (cb) {
        let info = { group: type };
        const list = await selectListDict(info);
        this.feeTypeList = list.data;
      }
    },
    getScheduleTriplist(cb) {
      if (cb) {
        if (!this.params.refId) {
          this.$message({ type: "warning", message: "请选择日程!" });
          return false;
        }
        getScheduleTriplist(this.params.refId).then((res) => {
          if (res.success) {
            this.scheduleTriplist = res.data;
          }
        });
      }
    },
    //1
    findMyProjectWorkflowMembers(cb) {
      if (!cb) return;

      let param = {};
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;
        }
      });
    },
    //
    getCalendarScheduleSelectList(cb) {
      console.log(cb);
      if (cb) {
        let info = { mode: 1, officeModeList: ["3", "4"] };
        getCalendarScheduleSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;
            this.calendarScheduleSelectList = list;
          }
        });
      }
    },
    expenseNoticeUser(id, refName) {
      this.$confirm("确定要催TA处理: [" + refName + "] , 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          expenseNoticeUser({ id: id }).then((response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "催TA成功!",
              });
              this.queryExpenseList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    expenseStartFormalApprove(row) {
      this.$confirm(
        "确定要发起报销申请: [" + row.refName + "]  , 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: false,
        }
      )
        .then(() => {
          expenseStartFormalApprove({ id: row.id }).then((response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "发起报销申请成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
            row.launchLoading = false;
            this.$forceUpdate();
            this.queryExpenseList(
              this.pageInfo.pageNum,
              this.pageInfo.pageSize
            );
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
          row.launchLoading = false;
          this.$forceUpdate();
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      this.queryExpenseList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.queryExpenseList(val, this.pageInfo.pageSize);
    },
    queryExpenseList(num, pageSize) {
      let param = {
        ...this.params,
      };
      param.pageNum = num;
      param.pageSize = pageSize;
      // param.userId = this.userId;

      getExpenseList(param).then((response) => {
        let tableData = response.data;
        tableData.map((item) => {
          this.$set(item, "launchLoading", false);
          return item;
        });
        this.tableData = tableData;

        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },
    openButtonControlDialog(code) {
      this.roleCode = code;
      this.buttonControlDialog = true;
    },
    //获取日程详情
    openScheduleDetails(id) {
      getCalendarThingDetails(id).then((response) => {
        this.scheduleDetails = response.data;
        // this.scheduleDetailsDialog = true;
      });
    },
    //新增编辑弹窗显示
    openDialog(type, row) {
      this.typeDialog = type;
      if (type == "add") {
        this.expenseDetailType = "add";
        this.expenseDetailDialog = true;
      } else if (type == "confirm") {
        console.log("norow");
        if (row) {
          this.editDialog = true;
          this.costId = row.id;
          this.costInfo = row;
        }
      } else if (type == "urge") {
        this.expenseNoticeUser(row.id, row.refName);
      } else if (type == "launch") {
        row.launchLoading = true;
        getCalendarThingDetails(row.refId).then((response) => {
          let scheduleDetails = response.data;
          if (scheduleDetails.businessList.length > 0) {
            this.expenseStartFormalApprove(row);
          } else {
            this.$message({
              type: "warning",
              message: "请先关联项目！",
            });
            row.launchLoading = false;
            this.$forceUpdate();
          }
        });
      } else if (type == "search") {
        if (this.params.searchDate) {
          this.params.startDate = this.params.searchDate[0];
          this.params.endDate = this.params.searchDate[1];
        }
        this.queryExpenseList(1, this.pageInfo.pageSize);
      } else if (type == "check") {
        this.editDialog = true;
        this.costId = row.id;
        this.costInfo = row;
      }

      //
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.queryExpenseList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fromStyle {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .el-form-item {
    // display: flex;
    width: 35% !important;
    float: left;
    >>> .el-select {
      width: 100% !important;
    }
  }
}
.el-select {
  width: 100% !important;
}
.preApplyStatus {
  cursor: pointer;
}

/deep/ .el-table .cell {
  white-space: pre-line;
}
</style>
