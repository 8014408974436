<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-02-18 14:50:44
 * @LastEditTime: 2022-08-01 16:56:19
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/costManagement/edit.vue
-->


<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editDialog"
    center
    @close="dialogClose"
    width="1200px"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <el-form>
      <el-row type="flex" justify="end">
        <el-form-item v-show="type != 'check'">
          <el-button type="primary" @click="openDialog('add')"
            >新增费用</el-button
          >
        </el-form-item>
      </el-row>
    </el-form>

    <!-- 表格 -->
    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="45"> </el-table-column>
      <el-table-column label="日程" prop="refName"> </el-table-column>
      <el-table-column label="行程" prop="title"> </el-table-column>
      <el-table-column label="费用类型" prop="feeType">
        <template slot-scope="scope">
          <span>{{ scope.row.feeTypeName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="费用名称" prop="feeTitle">
        <template slot-scope="scope">
          <span>{{ scope.row.feeTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="费用发生时间" prop="feeStartDate" width="160">
        <template slot-scope="scope">
          <span> {{ scope.row.feeStartDate | formatDateFilter }} </span>
        </template>
      </el-table-column>
      <el-table-column label="申请金额" prop="feeAmount"> </el-table-column>
      <el-table-column label="核报金额" prop="settleAmount">
        <template slot-scope="scope">
          <span v-show="scope.row.settleAmount > 0">{{
            scope.row.settleAmount
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="费用关联人" prop="userName">
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.relatedUserList"
            :key="index"
            class=""
          >
            <div v-if="item.userName">{{ index + 1 }}.{{ item.userName }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否替票" prop="isSubstituteTicket">
        <template slot-scope="scope">
          <span>
            {{ scope.row.isSubstituteTicket | isSubstituteTicket }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="附件" prop="fileList" width="170">
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.fileList"
            :key="index"
            class="fileList"
          >
            <el-link @click="handleExport(item)">
              <p v-if="item.originalFileName">
                {{ index + 1 }}.{{ item.originalFileName }}
              </p>
              <p v-else>{{ index + 1 }}.{{ item.name }}</p>
            </el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        width="220"
        v-if="type != 'check'"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="openDialog('edit', scope.row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="openDialog('copy', scope.row)"
            >复制</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="deleteExpenseDetail(scope.row, scope.$index)"
            >删除</el-button
          >

          <!-- <el-button
            type="primary"
            size="mini"
            @click="openDialog('file', scope.row)"
            >+附件</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <div class="total flexFcAFs">
      <div class="flexJfAc" v-show="totalCost">
        <span class="title">总费用：¥</span> <span>{{ totalCost }}</span>
      </div>
      <div class="flexJfAc" v-show="settleAmount">
        <span class="title">核报总费用：¥</span><span>{{ settleAmount }}</span>
      </div>
    </div>
    <div class="dialog-footer" v-show="type != 'check'">
      <el-button @click="dialogClose">取消</el-button>

      <el-popover placement="top" width="260" v-model="popoverVisible">
        <p>确定要一键确认所有费用？</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="batchCancel"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :disabled="tableData.length == 0"
            @click="batchConfirm"
            >确定</el-button
          >
        </div>
        <el-button
          type="primary"
          slot="reference"
          :disabled="tableData.length == 0"
          @click="sure"
          >一键确认</el-button
        >
      </el-popover>
      <el-popover placement="top" width="260" v-model="popoverVisible0">
        <p>0费用，确定要确认费用？</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="popoverVisible0 = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="batchConfirm"
            >确定</el-button
          >
        </div>
        <el-button
          type="text"
          slot="reference"
          :disabled="tableData.length != 0"
          >0费用确认？</el-button
        >
      </el-popover>
    </div>

    <editExpenseDetail
      v-if="expenseDetailDialog"
      :expenseDetailDialog="expenseDetailDialog"
      v-on:listenExpenseDetailClose="listenExpenseDetailClose"
      :detail="expenseDetail"
      :type="expenseDetailType"
      :id="expenseDetailId"
      :scheduleId="scheduleId"
    ></editExpenseDetail>
    <!-- 上传文件 -->
    <addFile
      v-if="addFileiDalogVisible"
      :addFileiDalogVisible="addFileiDalogVisible"
      :relevantFile="fileTitle"
      v-on:listenAddFileClose="listenAddFileClose"
    ></addFile>
  </el-dialog>
</template>

<script>
import {
  expenseConfirm,
  expenseBatchConfirm,
  deleteExpenseDetail,
  expenseAddAttachments,
  getExpenseDetailList,
  expenseNoticeFinancial,
  expensePreApprove,
  expenseBatchPreApprove,
} from "@/api/costManagement";
import { selectListDict } from "@/api/dataManagement";
import { formatDate } from "@/common/date";
import { findMyProjectWorkflowMembers } from "@/api/myMission";
import editExpenseDetail from "@/components/costManagement/editExpenseDetail";
import addFile from "@/components/addFile/addFile";

export default {
  name: "edit",
  data() {
    return {
      title: "新增",
      token: null,
      formData: {},
      rules: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入部门描述", trigger: "blur" },
        ],
      },

      //
      tableData: [],
      multipleSelection: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      calendarScheduleSelectList: [],
      myProjectWorkflowMembers: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      expenseDetail: null,
      expenseDetailDialog: false,
      expenseDetailType: null,
      feeTypeList: [],
      expenseDetailId: null,
      addFileiDalogVisible: false, //上传文件
      fileTitle: "费用附件",
      popoverVisible: false,
      scheduleId: null,
      popoverVisible0: false,
      totalCost: null,
      settleAmount: null,
    };
  },
  props: {
    type: String,
    editDialog: Boolean,
    id: Number,
    name: String,
    costInfo: Object,
  },
  components: {
    editExpenseDetail,
    addFile,
  },
  watch: {
    costInfo: {
      handler(newVal, oldVal) {
        let that = this;
        if (newVal) {
          that.scheduleId = that.costInfo.refId;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    formatDateFilter(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    isSubstituteTicket(value) {
      switch (value) {
        case 0:
          return "否";
        case 1:
          return "是";
      }
    },
  },
  computed: {},
  created() {
    if (this.type == "confirm") {
      this.title = "申请人填报 & 确认";
      this.getExpenseDetailList();
    } else if (this.type == "check") {
      this.title = "查看费用";
      this.getExpenseDetailList();
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  mounted() {
    this.selectListDict("expense_detail_type");
  },
  methods: {
    handleExport(item) {
      let fileType = item.url.split(".")[item.url.split(".").length - 1];
      this.$router.push({
        name: "officeOnLine",
        params: { file: item, url: item.url, fileType: fileType },
      });
    },
    //
    getExpenseDetailList() {
      getExpenseDetailList({ expenseId: this.id }).then((response) => {
        if (response.success) {
          let list = response.data;
          let feeAmountList = [];
          let settleAmountList = [];
          list.map(function (item, index) {
            item.fileList = JSON.parse(item.attachments);
          });
          feeAmountList = list.map((x) => x.feeAmount);
          let totalCost = eval(feeAmountList.join("+"));
          this.totalCost = totalCost.toFixed(2);
          settleAmountList = list.map((x) => x.settleAmount);
          let totalsettleAmount = eval(settleAmountList.join("+"));
          this.settleAmount = totalsettleAmount.toFixed(2);
          this.tableData = list;
        }
      });
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      console.log(params);
      const { type, isClose, files } = params;
      this.addFileiDalogVisible = !isClose;
      if (type == "sure") {
        console.log(files);
        expenseAddAttachments({ id: this.expenseDetail.id, files: files }).then(
          (response) => {
            if (response.success) {
              this.$message({
                type: "success",
                message: "上传附件成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          }
        );
      }
    },
    deleteExpenseDetail(row, index) {
      console.log(index);
      this.$confirm("确定要删除费用：" + row.title + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteExpenseDetail({ id: row.id }).then((response) => {
            if (response.success) {
              this.tableData.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async selectListDict(type) {
      if (!this.feeTypeList) {
        let info = { group: type };
        const list = await selectListDict(info);
        this.feeTypeList = list.data;
      }
    },
    listenExpenseDetailClose(params) {
      let that = this;
      console.log(params);
      const { type, isClose, info } = params;
      that.expenseDetailDialog = !isClose;
      if (type == "sure") {
        this.getExpenseDetailList();
      }
    },
    findMyProjectWorkflowMembers(cb) {
      if (!cb) return;

      let param = {};
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;
        }
      });
    },

    //新增编辑弹窗显示
    openDialog(type, row) {
      this.expenseDetailType = type;
      this.expenseDetail = row;

      if (type == "edit") {
        this.expenseDetailDialog = true;
      } else if (type == "add") {
        this.expenseDetailDialog = true;
      } else if (type == "copy") {
        delete this.expenseDetail.id;
        this.expenseDetailDialog = true;
      } else if (type == "file") {
        this.addFileiDalogVisible = true;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //弹窗关闭
    dialogClose() {
      this.$emit("listenEditClose", { type: "cancel", isClose: true });
    },

    //确定
    sure() {
      let that = this;
      if (that.tableData) {
        this.multipleSelection = this.tableData;
        that.tableData.forEach((row) => {
          that.$refs.multipleTable.toggleRowSelection(row);
        });
      }
      // that.batchConfirm();
    },
    batchCancel() {
      let that = this;
      that.tableData.forEach((row) => {
        that.$refs.multipleTable.toggleRowSelection(row);
      });
      this.popoverVisible = false;
    },
    batchConfirm() {
      // let list = this.multipleSelection.map(function (value, index, arr) {
      //   if (value.expenseId != null) return value.expenseId;
      // });

      let idList = [this.id];
      // console.log(idList);
      expenseBatchConfirm({ idList: idList }).then((response) => {
        if (response.success) {
          if (this.popoverVisible == true) {
            this.popoverVisible = false;
            this.$message({
              type: "success",
              message: "一键确认成功!",
            });
          } else if (this.popoverVisible0 == true) {
            this.popoverVisible0 = false;
            this.$message({
              type: "success",
              message: "0费用确认成功!",
            });
          }

          this.$emit("listenEditClose", { type: "sure", isClose: true });
        } else {
          this.$refs.multipleTable.clearSelection();
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}

.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 100%;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
.dialog-footer {
  margin-top: 20px;
}
.total {
  margin: 20px 0 0 20px;
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  div {
    .title {
      // display: inline-block;
      min-width: 130px;
      text-align: right;
    }
  }
}
</style>